import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, EventEmitter, Inject, OnDestroy, Output, Renderer2} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {AuthenticationService} from '@Core/services/authentication/authentication.service';

@Component({
  selector: 'app-reinz-error-page',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './reinz-error-page.component.html',
  styleUrl: './reinz-error-page.component.scss'
})
export class ReinzErrorPageComponent implements AfterViewInit, OnDestroy {
  @Output() logOut = new EventEmitter();
  
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly authService: AuthenticationService
  ) {}

  handleLogOut(): void {
    this.authService.logout();
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.document.body, 'search-page--overlay');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'search-page--overlay');
  }
}
