// Create a CoreModule with providers for the singleton services you load when the application starts.
// Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
// Consider making CoreModule a pure services module with no declarations.

// Angular Modules
import {NgModule, ModuleWithProviders, Optional, SkipSelf, LOCALE_ID} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';

// Application Modules
import * as fromComponents from '@Core/components';
import {environment} from '@Env/environment';

// Material Modules
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatNativeDateModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

// Application Interceptors
import {AuthorizeInterceptor} from '@Core/interceptors/authorize.interceptor';

// Application Factories
import {authStorageFactory} from '@Core/factories/auth-storage/auth-storage.factory';
import {AbstractFeatureConfigProvider} from './services/feature-flag/abstract-feature-config-provider';
import {FeatureConfigProvider} from './services/feature-flag/feature-config-provider';
import {EnvironmentFeatureConfigProvider} from './services/feature-flag/environment-feature-config-provider';
import {LaunchDarklyFeatureConfigProvider} from './services/feature-flag/launch-darkly-feature-config-provider';
import {DirectivesModule} from '@Shared/directives/directives.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';

// Application Guards

// Register Locale
import localeEnNZ from '@angular/common/locales/en-NZ';
import localeEnAU from '@angular/common/locales/en-AU';
const locales = {
  'en-NZ': localeEnNZ,
  'en-AU': localeEnAU,
} as const;

registerLocaleData(locales[environment.localeID as keyof typeof locales]);

@NgModule({
  declarations: [[...fromComponents.components]],
  imports: [
    // Angular Modules
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    // Application Modules
    OAuthModule.forRoot(),
    MatNativeDateModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    RouterModule,
    DirectivesModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: OAuthStorage,
      useFactory: authStorageFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeInterceptor,
      multi: true,
    },
    {provide: LOCALE_ID, useValue: environment.localeID},
    {provide: AbstractFeatureConfigProvider, useClass: FeatureConfigProvider},
    {provide: EnvironmentFeatureConfigProvider, useClass: EnvironmentFeatureConfigProvider},
    {provide: LaunchDarklyFeatureConfigProvider, useClass: LaunchDarklyFeatureConfigProvider},
  ],
  exports: [[...fromComponents.components]],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
