import {inject, Injectable, OnDestroy} from '@angular/core';
import {ProfileManagementService} from '@Core/services/profile-management/profile-management-service';
import {MyProfileSummary} from '@Shared/interfaces/my-profile-summary';
import {catchError, combineLatest, filter, Observable, of, Subject, switchMap, takeUntil} from 'rxjs';
import {Router} from '@angular/router';
import {ProfileUserSettings} from '@Shared/enums/profile-user-settings';
import {RealEstateApiService} from '@Core/services/real-estate-api/real-estate-api.service';
import {FeatureFlagsService} from '@Core/services/feature-flag/feature-flags.service';
import {FeatureFlags} from '@Shared/enums/feature-flags';
import {ENVIRONMENT} from '@Env/environment.provider';
import {AuthorizationService} from '@Core/services/authorization/authorization.service';
import {ProfilePermissions} from '@Pages/user-management/enums/profile-permissions';
import {ProfileApiService} from '@Core/services/profile-api/profile-api.service';
import {ProductFeatureCode} from '@Shared/enums/product-feature-code';

@Injectable({
  providedIn: 'root',
})
export class ReinzGuard implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly router: Router = inject(Router);
  private readonly environment = inject(ENVIRONMENT);
  private readonly featureFlagService = inject(FeatureFlagsService);
  private readonly realEstateService: RealEstateApiService = inject(RealEstateApiService);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  private readonly profileManagementService: ProfileManagementService = inject(ProfileManagementService);
  private readonly profileApiService: ProfileApiService = inject(ProfileApiService);

  reinzMembershipValidated = false;
  reinzMembershipIsValid = false;

  canActivate(): Observable<boolean> {
    if (!this.isReinzValidationApplicable()) {
      return of(true);
    }

    if (this.reinzMembershipValidated) {
      return of(this.reinzMembershipIsValid);
    }

    return combineLatest([
      this.authorizationService.hasPermission([ProfilePermissions.valocityImplementer]),
      this.profileManagementService.profileSummary.pipe(
        filter((profile) => this.isValidProfile(profile)),
        takeUntil(this.destroy$)
      ),
      this.profileApiService.isProductFeatureEnabled(ProductFeatureCode.reinzAccess),
    ]).pipe(
      switchMap(([isUserImplementer, profile, productFeatureEnabled]) => {
        if (!productFeatureEnabled) {
          return of(true);
        }
        if (isUserImplementer) {
          this.reinzMembershipValidated = true;
          this.reinzMembershipIsValid = true;
          return of(true);
        }

        const reinzId = this.tryGetUserSetting(profile);
        if (!reinzId) {
          this.redirectToReinzErrorPage();
          return of(false);
        }

        return this.fetchNexusUserSetting(reinzId);
      })
    );
  }

  private fetchNexusUserSetting(reinzId: string): Observable<boolean> {
    return this.realEstateService.getNexusUserSettings(reinzId).pipe(
      switchMap((response) => {
        this.reinzMembershipValidated = true;
        this.reinzMembershipIsValid = response.isReinzMember;

        if (response.isReinzMember) {
          return of(true);
        } else {
          this.redirectToReinzErrorPage();
          return of(false);
        }
      }),
      catchError(() => {
        this.redirectToErrorPage();
        return of(false);
      })
    );
  }

  private isReinzValidationApplicable(): boolean {
    return (
      this.environment.countryCode === 'NZ' &&
      this.featureFlagService.isFeatureEnabled(FeatureFlags.reinzMembershipValidationEnabled)
    );
  }

  private tryGetUserSetting(profile: Partial<MyProfileSummary>): string {
    const userSetting = profile.userSettings;
    const reinzMembership = userSetting?.find((setting) => setting.key === ProfileUserSettings.ReinzMembershipId);
    return reinzMembership ? reinzMembership.value : '';
  }

  private redirectToReinzErrorPage(): void {
    this.router.navigate(['/reinz-error']);
  }

  private redirectToErrorPage(): void {
    this.router.navigate(['/error'], {
      queryParams: {
        errorCode: 500,
      },
    });
  }

  private isValidProfile(profile: Partial<MyProfileSummary>): boolean {
    return (
      profile.preferredName !== '' ||
      profile.avatarThumbnailImageUri !== '' ||
      (profile.userSettings !== undefined && profile.userSettings.length > 0)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
