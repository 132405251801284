<div class="error__container">
    <div class="error__message">
      <mat-icon class="error__icon">warning</mat-icon>
      <h1>REINZ ID Validation Failed</h1>
      <p>We were unable to validate your REINZ ID with the REINZ system.</p>
      <p>Please reach out to REINZ Support 
        <a href="mailto:info@reinz.co.nz">info&#64;reinz.co.nz</a> 
        to ensure that your REINZ ID is valid.
      </p>
      <p>Alternatively, contact our support team 
        <a href="mailto:support@valocitynexus.co.nz">support&#64;valocitynexus.co.nz</a>
        if you need assistance updating your REINZ ID.
      </p>
      <button
        mat-flat-button
        color="primary"
        class="logout__button"
        (click)="handleLogOut()"
        >
          <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
          Back to Login
      </button>
    </div>
</div>
